



















































import { TipoReceita } from "@/core/models/financeiro";
import { PageBase } from "@/core/models/shared";
import { TipoReceitaService } from "@/core/services/financeiro";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaTipoReceita extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Nome', value: 'nome'}
    ];
    item = new TipoReceita();
    service = new TipoReceitaService();

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){

        if(isFiltro == true)
        {
            this.options.page = 1;
        }

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;

        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers).then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            AlertSimpleErr("Aviso!", err)
        })
        .finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: TipoReceita){
        if(item){
            this.service.ObterPorId(item.id).then(
                res=>{
                    this.item = new TipoReceita(res.data);
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            this.item = new TipoReceita();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: TipoReceita){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
